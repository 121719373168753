import { SnippetViewModel } from './model/snippet';
import { Snippet } from 'src/app/model/snippet';
import {
  FamilyProfileModel,
  PersonBio,
  BudgetingInfo,
  FinancialInfo,
  FamilyProfileDetailsModel,
  FullFamilyProfileViewModel,
  WorldEditable,
  Comment,
  PartialFamilyProfileViewModel,
} from './model/portrait';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { take, map } from 'rxjs/operators';

import * as _ from 'lodash';
import { isRegExp } from 'util';

interface ProfilesObject {
  [key: string]: FamilyProfileModel;
}

interface ExtendedProfilesObject {
  [key: string]: PartialFamilyProfileViewModel;
}

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  inMemoryProfiles: ExtendedProfilesObject | undefined;
  inMemoryDetailedProfiles = new Map<string, FamilyProfileDetailsModel>();
  inMemorySnippets: { [key: string]: SnippetViewModel } | undefined;

  inMemoryProfilesStaging: ExtendedProfilesObject | undefined;
  inMemoryDetailedProfilesStaging = new Map<
    string,
    FamilyProfileDetailsModel
  >();
  inMemorySnippetsStaging: { [key: string]: SnippetViewModel } | undefined;

  // Map from profile ID to a a map of comment id to comment
  inMemoryComments: { [key: string]: { [key: string]: Comment } } = {};

  constructor(private auth: AngularFireAuth, private db: AngularFireDatabase) {}

  async saveSnippet(snippet: Snippet, prod?: boolean): Promise<string> {
    if (!snippet.snippetId) {
      snippet.snippetId = this.db.createPushId();
    }
    const basePath = (prod ? 'prod' : 'pending') + '/';
    const snippetPath = basePath + 'snippets/' + snippet.snippetId;

    this.trimObject(snippet);

    this.db.object(snippetPath).set(snippet);

    const snippetVm = {
      ...snippet,
      prod,
    };
    if (prod && this.inMemorySnippets) {
      this.inMemorySnippets[snippet.snippetId] = snippetVm;
    } else if (!prod && this.inMemorySnippetsStaging) {
      this.inMemorySnippetsStaging[snippet.snippetId] = snippetVm;
    }

    return snippet.snippetId;
  }

  async listSnippets(
    prod?: boolean
  ): Promise<{ [key: string]: SnippetViewModel }> {
    if (prod && this.inMemorySnippets) {
      return this.inMemorySnippets;
    }
    if (!prod && this.inMemorySnippetsStaging) {
      return this.inMemorySnippetsStaging;
    }

    const basePath = (prod ? 'prod' : 'pending') + '/';
    const snippetsPath = basePath + 'snippets';

    // TODO: Error handling
    const snippetsObject: { [key: string]: Snippet } =
      (await this.db
        .object<{ [key: string]: Snippet }>(snippetsPath)
        .valueChanges()
        .pipe(take(1))
        .toPromise()) || {};

    const extendedSnippetsObject: {
      [key: string]: SnippetViewModel;
    } = _.mapValues(snippetsObject, (value) => {
      return { ...value, prod: !!prod };
    });

    if (prod) {
      this.inMemorySnippets = extendedSnippetsObject;
    } else {
      this.inMemorySnippetsStaging = extendedSnippetsObject;
    }

    return extendedSnippetsObject;
  }

  async deleteSnippet(snippetId: string, prod?: boolean) {
    // TODO: Validate objects before saving them.
    const basePath = (prod ? 'prod' : 'pending') + '/';

    const snippetPath = basePath + 'snippets/' + snippetId;
    await this.db.object(snippetPath).remove();
    if (prod) {
      delete this.inMemorySnippets[snippetId];
    } else {
      delete this.inMemorySnippetsStaging[snippetId];
    }
  }

  /**
   * Returns the profileId of the saved / created profile.
   * @param familyProfile
   * @param profileDetails
   * @param profileId
   * @param prod
   */
  async saveFullFamilyProfile(
    familyProfile: FamilyProfileModel,
    profileDetails: FamilyProfileDetailsModel,
    prod?: boolean
  ): Promise<string> {
    if (!!familyProfile.profileId !== !!profileDetails.profileId) {
      throw new Error('Profile ID cannot be partly provided.');
    }
    let profileId = familyProfile.profileId;
    if (!profileId) {
      // This indicates a new creation.
      profileId = this.db.createPushId();
      familyProfile.profileId = profileId;
      profileDetails.profileId = profileId;
    }

    this.trimObject(familyProfile);
    this.trimObject(profileDetails);

    // TODO: Validate objects before saving them.
    const basePath = (prod ? 'prod' : 'pending') + '/';

    const profilePath = basePath + 'profiles/' + profileId;
    await this.db.object(profilePath).set(familyProfile);

    const detailsPath = basePath + 'details/' + profileId;
    await this.db.object(detailsPath).set(profileDetails);

    const extendedFamilyProfile: PartialFamilyProfileViewModel = {
      ...familyProfile,
      prod: !!prod,
    };

    if (prod && this.inMemoryProfiles) {
      this.inMemoryProfiles[profileId] = extendedFamilyProfile;
      this.inMemoryDetailedProfiles.set(profileId, profileDetails);
    } else if (!prod && this.inMemoryProfilesStaging) {
      this.inMemoryProfilesStaging[profileId] = extendedFamilyProfile;
      this.inMemoryDetailedProfilesStaging.set(profileId, profileDetails);
    }

    return profileId;
  }

  // This assumes not very deep nested objects and does recursion.
  // Mutates the object inplace.
  private trimObject(object: any) {
    if (!object) {
      return;
    }
    if (!_.isPlainObject(object)) {
      return;
    }
    for (const kvp of Object.entries(object)) {
      // Trim recursively first
      if (_.isPlainObject(kvp[1])) {
        this.trimObject(kvp[1]);
        if (_.isEmpty(kvp[1])) {
          delete object[kvp[0]];
        }
      } else if (kvp[1] === '') {
        delete object[kvp[0]];
      } else if (kvp[1] === undefined) {
        delete object[kvp[0]];
      } else if (_.isArray(kvp[1])) {
        const updatedArray = [];
        for (const val of kvp[1] as any[]) {
          this.trimObject(val);
          if (!_.isEmpty(val)) {
            updatedArray.push(val);
          }
        }
        if (_.isEmpty(updatedArray)) {
          delete object[kvp[0]];
        } else {
          object[kvp[0]] = updatedArray;
        }
      }
    }
  }

  async listProfiles(prod?: boolean): Promise<ExtendedProfilesObject> {
    if (prod && this.inMemoryProfiles) {
      return this.inMemoryProfiles;
    }
    if (!prod && this.inMemoryProfilesStaging) {
      return this.inMemoryProfilesStaging;
    }

    const basePath = (prod ? 'prod' : 'pending') + '/';
    const profilesPath = basePath + 'profiles';

    // TODO: Error handling
    const profilesObject: ProfilesObject = await this.db
      .object<ProfilesObject>(profilesPath)
      .valueChanges()
      .pipe(take(1))
      .toPromise();

    const extendedProfilesObject: ExtendedProfilesObject = _.mapValues(
      profilesObject,
      (value) => {
        return { ...value, prod: !!prod };
      }
    );

    if (prod) {
      this.inMemoryProfiles = extendedProfilesObject;
    } else {
      this.inMemoryProfilesStaging = extendedProfilesObject;
    }

    return extendedProfilesObject;
  }

  async getProfileModel(profileId: string, prod?: boolean) {
    const profiles = await this.listProfiles(prod);
    if (!profiles[profileId]) {
      throw new Error('Profile not found');
    }
    return profiles[profileId];
  }

  async getFullFamilyProfileViewModel(
    profileId: string,
    prod?: boolean
  ): Promise<FullFamilyProfileViewModel> {
    // Get the high-level info.
    const allProfiles = await this.listProfiles(prod);
    const profileModel = allProfiles[profileId];
    if (!profileModel) {
      throw new Error('Could not find profile with id ' + profileId);
    }
    const profileDetails = await this.getProfileDetails(profileId, prod);
    if (!profileDetails) {
      throw new Error('Could not find profile with id ' + profileId);
    }

    return this.constructProfileViewModel(profileModel, profileDetails, !!prod);
  }

  private constructProfileViewModel(
    profileModel: FamilyProfileModel,
    profileDetails: FamilyProfileDetailsModel,
    prod: boolean
  ): FullFamilyProfileViewModel {
    return {
      profileId: profileModel.profileId,
      familyInfo: profileModel.familyInfo,
      linkInfo: profileModel.linkInfo || {},
      worldEditable: profileModel.worldEditable,
      adultDetails: profileDetails.adultDetails || [],
      budgetInfo: profileDetails.budgetInfo || [],
      financialInfo: profileDetails.financialInfo || [],
      prod,
    };
  }

  async getProfileDetails(profileId: string, prod?: boolean) {
    if (prod && this.inMemoryDetailedProfiles.has(profileId)) {
      return this.inMemoryDetailedProfiles.get(profileId);
    }
    if (!prod && this.inMemoryDetailedProfilesStaging.has(profileId)) {
      return this.inMemoryDetailedProfilesStaging.get(profileId);
    }

    const basePath = (prod ? 'prod' : 'pending') + '/';
    const profilePath = basePath + 'details/' + profileId;

    const detail: FamilyProfileDetailsModel = await this.db
      .object<FamilyProfileDetailsModel>(profilePath)
      .valueChanges()
      .pipe(take(1))
      .toPromise();

    if (prod) {
      this.inMemoryDetailedProfiles.set(profileId, detail);
    } else {
      this.inMemoryDetailedProfilesStaging.set(profileId, detail);
    }
    return detail;
  }

  async deleteProfile(profileId: string, prod?: boolean) {
    // TODO: Validate objects before saving them.
    const basePath = (prod ? 'prod' : 'pending') + '/';

    const profilePath = basePath + 'profiles/' + profileId;
    await this.db.object(profilePath).remove();

    const detailsPath = basePath + 'details/' + profileId;
    await this.db.object(detailsPath).remove();

    if (prod) {
      delete this.inMemoryProfiles[profileId];
      this.inMemoryDetailedProfiles.delete(profileId);
    } else {
      delete this.inMemoryProfilesStaging[profileId];
      this.inMemoryDetailedProfilesStaging.delete(profileId);
    }
  }

  async moveToProd(profileId) {
    const profileModel = await this.getProfileModel(profileId, false);
    const profileDetails = await this.getProfileDetails(profileId, false);

    // Save to prod
    await this.saveFullFamilyProfile(
      profileModel,
      profileDetails,
      true
    );

    // Delete from staging
    await this.deleteProfile(profileId, false);
  }

  async moveToStaging(profileId) {
    const profileModel = await this.getProfileModel(profileId, true);
    const profileDetails = await this.getProfileDetails(profileId, true);

    // Save to staging
    await this.saveFullFamilyProfile(
      profileModel,
      profileDetails,
      false
    );

    // Delete from prod
    await this.deleteProfile(profileId, true);
  }

  async moveSnippetToProd(snippetId: string) {
    const stagingSnippet = this.inMemorySnippetsStaging[snippetId];
    if (!stagingSnippet) {
      // TODO: Error
      return;
    }

    // Save to prod
    await this.saveSnippet(stagingSnippet, true);

    // Delete from staging
    await this.deleteSnippet(snippetId, false);
  }

  async moveSnippetToStaging(snippetId: string) {
    const prodSnippet = this.inMemorySnippets[snippetId];
    if (!prodSnippet) {
      // TODO: Error
      return;
    }

    // Save to staging
    await this.saveSnippet(prodSnippet, false);

    // Delete from prod
    await this.deleteSnippet(snippetId, true);
  }

  async isSpecialUser() {
    const user = await this.auth.currentUser;
    if (user && user.uid === 'DvwTQvjIWEcEY8HwJsCwTVPkWoD3') {
      return true;
    }
    return false;
  }

  isSpecialUserObservable() {
    return this.auth.user.pipe(
      map((user) => {
        if (user && user.uid === 'DvwTQvjIWEcEY8HwJsCwTVPkWoD3') {
          return true;
        }
        return false;
      })
    );
  }

  async incrementLike(profileId: string, prod?: boolean) {
    const path =
      (prod ? 'prod' : 'pending') + '/profiles/' + profileId + '/worldEditable';
    // Get the current value
    const currentWorldEditable = await this.db
      .object<WorldEditable>(path)
      .valueChanges()
      .pipe(take(1))
      .toPromise();
    const updatedWorldEditable = {
      ...currentWorldEditable,
      likes: ((currentWorldEditable && currentWorldEditable.likes) || 0) + 1,
    };
    await this.db.object(path).set(updatedWorldEditable);

    // Update the in-memory version.
    if (prod) {
      this.inMemoryProfiles[profileId].worldEditable = updatedWorldEditable;
    } else {
      this.inMemoryProfilesStaging[
        profileId
      ].worldEditable = updatedWorldEditable;
    }
  }

  async listComments(parentId: string) {
    if (this.inMemoryComments[parentId]) {
      return this.inMemoryComments[parentId];
    }
    const path = 'comments/' + parentId;
    const comments = await this.db
      .object<{ [key: string]: Comment }>(path)
      .valueChanges()
      .pipe(take(1))
      .toPromise();
    this.inMemoryComments[parentId] = comments;
    return comments;
  }

  async saveComment(comment: Comment) {
    // Comments are nested under profile id.
    if (!comment.commentId) {
      comment.commentId = this.db.createPushId();
    }
    const path = 'comments/' + comment.parentId + '/' + comment.commentId;

    // TODO: Validate
    await this.db.object(path).set(comment);
    if (!this.inMemoryComments[comment.parentId]) {
      this.inMemoryComments[comment.parentId] = {};
    }
    this.inMemoryComments[comment.parentId][comment.commentId] = comment;
  }
}
