<div class="global-top-section">
  <div>
    <div class="global-big-heading">
      Snippets
    </div>
    <div class="wrap">
      <div class="first">
        Snippets provide short summaries of interesting and important
        FIRE-related topics.
        <div>
          <a
            mat-raised-button
            color="primary"
            routerLink="/edit-snippet"
            queryParamsHandling="preserve"
          >
            Submit new snippet
          </a>
        </div>
      </div>
      <form [formGroup]="browseForm">
        <div>
          <mat-slide-toggle formControlName="includeUnconfirmed">
            Include unconfirmed submissions
          </mat-slide-toggle>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Search</mat-label>
            <input matInput formControlName="search" />
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="container" *ngIf="snippets">
  <mat-card
    *ngFor="let snippet of snippets"
    [routerLink]="'/snippets/' + snippet.snippetId + '/public/' + snippet.prod"
    queryParamsHandling="preserve"
  >
    <mat-menu #appMenu="matMenu">
      This is the admin menu.
      <button mat-menu-item (click)="changePublish(snippet)">
        Toggle publish
      </button>
      <button mat-menu-item (click)="delete(snippet)">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-menu>
    <mat-card-title [class]="snippet.prod ? 'prod-snippet' : 'staging-snippet'">
      <div class="title-text">
        {{ snippet.title }}
      </div>
      <button
        mat-icon-button
        [matMenuTriggerFor]="appMenu"
        (click)="$event.stopPropagation()"
        *ngIf="isSpecialUser$ | async"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </mat-card-title>
    <mat-card-content>
      <div class="snippet-text">
        {{ snippet.text }}
      </div>
      <div>
        <mat-chip-list>
          <mat-chip *ngFor="let tag of getTags(snippet)">{{ tag }}</mat-chip>
        </mat-chip-list>
      </div>
    </mat-card-content>
  </mat-card>
</div>
