export enum FinancialGoal {
  UNKNOWN,
  DEBT_FREE,
  FINANCIAL_INDEPENDENCE,
}

export enum Frugality {
  UNKNOWN,
  FRUGAL,
  TYPICAL,
  LAVISH,
}

export enum BudgetStyle {
  UNKNOWN,
  LIVING_THERE,
  TOURIST,
}

export interface FullFamilyProfileViewModel {
  profileId: string;

  prod: boolean;

  familyInfo: FamilyInfo;
  linkInfo: LinkInfo;

  // Note that a family with 2 adults might have *no* PersonBio, or only one PersonBio.
  adultDetails: PersonBio[];
  budgetInfo: BudgetingInfo[];
  financialInfo: FinancialInfo[];
  worldEditable?: WorldEditable;
}

export interface PartialFamilyProfileViewModel extends FamilyProfileModel {
  prod: boolean;
}

export interface FamilyProfileModel {
  // Output only. Will be undefined for a brand new FamilyInfo.
  profileId?: string;

  // Indicates that this is an update for an existing prod profile.
  updateForProdProfileId?: string;

  familyInfo: FamilyInfo;

  // Can be undefined if nothing is provided for any link.
  linkInfo?: LinkInfo;

  worldEditable?: WorldEditable;
}

export interface Comment {
  // Profile ID or snippet id.
  parentId: string;
  commentId?: string;
  author: string;
  text: string;
  date: Date;
}

export interface FamilyProfileDetailsModel {
  // Output only. Will be undefined for a brand new FamilyInfo.
  profileId?: string;

  // All of the below is optional.
  adultDetails?: PersonBio[];
  budgetInfo?: BudgetingInfo[];
  financialInfo?: FinancialInfo[];
}

export interface WorldEditable {
  likes?: number;
}

interface LinkInfo {
  readMoreUrl?: string;
  websiteUrl?: string;
  instagramUrl?: string;
  twitterUrl?: string;
  emailAddress?: string;
}

interface FamilyInfo {
  // Note: Indicates child counts. Put N/A if unsure of age.
  childrenAges?: number[];
  familyName: string;
  backgroundUrl: string;
  adultCount: number;
  childrenCount: number;

  // Format: City, Country, Continent
  location: string;
  financialGoal: FinancialGoal;
  frugality: Frugality;

  // Some current information is required.
  // This one would be automatically determined.
  networth?: number;
  monthlyIncome?: number;
  monthlyExpenses?: number;
}

export interface PersonBio {
  // PersonBio itself is optional. But if it is specified, at least a name should be given.
  name: string;
  age?: number;
  pictureUrl?: string;
  pictureCredit?: string;
  profession?: string;
  briefBio?: string;
}

// There can be multiple FinancialInfo. the idea is that
// users could report progression.
export interface FinancialInfo {
  // Optional timestamp. If not specified, it is assumed to be 'now'.
  timestamp?: Date;

  networth?: number;
  monthlyIncome?: number;
  monthlyExpenses?: number;
}

// There could be multiple BudgetingInfo, same as above. Progression
// in time can be shown, and also change in location.
export interface BudgetingInfo {
  currencyCode: string;

  // Optional timestamp. If not specified, it is assumed to be 'now'.
  timestamp?: Date;

  // Just because the location might be different from the home base
  location?: string;

  // Some more explanation, e.g. on 'Other' expenses.
  moreInfo?: string;

  budgetingStyle?: BudgetStyle;

  // This is a 30 day time frame by default.
  budgetingDays?: number;

  budgetingLines?: {
    vacation?: number;
    personalItems?: number;
    // Includes rent, mortgage, utilities
    housing?: number;
    generalChildrenExpenses?: number;
    childrenEducation?: number;
    other?: number;
    tax?: number;
    medical?: number;
    transportation?: number;
    foodAndRestaurants?: number;
  };
}

const categoryToDisplayString = {
  vacation: 'Vacation',
  personalItems: 'Personal items',
  housing: 'Housing and utilities',
  generalChildrenExpense: 'General child expenses',
  childrenEducation: 'Child education',
  other: 'Other',
  tax: 'Taxes',
  medical: 'Health and insurance',
  transportation: 'Tranportation (car, insurance, public)',
  foodAndRestaurants: 'Food (groceries, restaurants)',
};

export const getCategoryDisplayString = (key: string) => {
  const displayString = categoryToDisplayString[key];
  if (displayString) {
    return displayString;
  }
  return undefined;
};
