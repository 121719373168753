<div class="global-top-section">
  <div>
    <div class="global-big-heading">
      Privacy policy
    </div>
    <div class="global-subtitle">
      Because your privacy is important.
    </div>
  </div>
</div>

<div class="container">
  <p>
    It is FIRE Hungry's policy to respect your privacy regarding any information
    we may collect while operating our website, in accordance with worldwide
    standards (including the EU's GDPR). Accordingly, we have developed this
    privacy policy in order for you to understand how we collect, use,
    communicate, disclose and otherwise make use of personal information. We
    have outlined our privacy policy below.
  </p>
  <br />
  <ul>
    <li>
      <p>
        We will collect personal information by lawful and fair means and, where
        appropriate, with the active consent of the individual concerned.
      </p>
    </li>
    <li>
      <p>
        We collect the following personal information on account signup: First
        name, last name, hashed password, and email address.
      </p>
    </li>
    <li>
      <p>
        Personal information is used only to enhance the user's experience with
        FIRE Hungry (e.g., to refer to you by name), and is not shared with or
        sold to any third parties.
      </p>
    </li>
    <li>
      <p>
        IP addresses are used in real time by various features of FIRE Hungry to
        provide location-specific features, such as nearest airport or probable
        country of origin. IP addresses may be stored in use logs, which are
        purged approximately weekly.
      </p>
    </li>
    <li>
      <p>
        Before or at the time of collecting personal information, we will
        identify the purposes for which information is being collected.
      </p>
    </li>
    <li>
      <p>
        If you contribute content data to the site, including but not limited to
        the crowdsourced profiles and snippets, and comments, you provide us
        with a perpetual license to broadcast, display, distribute, sell and
        produce derivatives from your contributions. None of the content you
        contribute, except for any information in the account you create, is
        considered personal data or information.
      </p>
    </li>
    <li>
      <p>
        We will only collect personal data that is relevant to the purposes for
        which it is to be used.
      </p>
    </li>
    <li>
      <p>
        We will protect personal information by using reasonable security
        safeguards against loss or theft, as well as unauthorized access,
        disclosure, copying, use or modification.
      </p>
    </li>
    <li>
      <p>
        We will make readily available to customers information about our
        policies and practices relating to the management of personal
        information.
      </p>
    </li>
    <li>
      <p>
        We will only retain personal information while you remain an active user
        of the site. If your account remains inactive for 24 months, the account
        and all related personal data will be purged from the system.
      </p>
    </li>
    <li>
      <p>
        You may request that we delete any and all personal information
        collected about you, or share any data collected about you in
        machine-readable format, by emailing contact@FIREHungry.com from the
        email account you used to register.
      </p>
    </li>
  </ul>
  <p>
    We are committed to conducting our business in accordance with these
    principles in order to ensure that the confidentiality of personal
    information is protected and maintained. FIRE Hungry may change this privacy
    policy from time to time at FIRE Hungry's sole discretion.
  </p>
</div>
