import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProfileDetailsComponent } from './components/profile-details/profile-details.component';
import { HomeComponent } from './components/home/home.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { EditPortraitComponent } from './components/edit-portrait/edit-portrait.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ViewProfilesComponent } from './components/view-profiles/view-profiles.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { ViewSnippetsComponent } from './components/view-snippets/view-snippets.component';
import { EditSnippetComponent } from './components/edit-snippet/edit-snippet.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AboutComponent } from './components/about/about.component';
import { TosComponent } from './components/tos/tos.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { MatMenuModule } from '@angular/material/menu';
import { SnippetDetailsComponent } from './snippet-details/snippet-details.component';
import { CommentsComponent } from './comments/comments.component';
import { MatChipsModule } from '@angular/material/chips';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ScullyLibModule } from '@scullyio/ng-lib';

const firebaseConfig = {
  apiKey: 'AIzaSyCaGs_wsLw4ZKG9ZYZOmxI4ok2TbwnIPwg',
  authDomain: 'figallery1.firebaseapp.com',
  databaseURL: 'https://figallery1.firebaseio.com',
  projectId: 'figallery1',
  storageBucket: 'figallery1.appspot.com',
  messagingSenderId: '232593634792',
  appId: '1:232593634792:web:381badf6d98bb14b86605d',
  measurementId: 'G-HGTXVDZ09T',
};

@NgModule({
  declarations: [
    AppComponent,
    ProfileDetailsComponent,
    HomeComponent,
    EditPortraitComponent,
    ViewProfilesComponent,
    ViewSnippetsComponent,
    EditSnippetComponent,
    SignInComponent,
    AboutComponent,
    TosComponent,
    PrivacyPolicyComponent,
    SnippetDetailsComponent,
    CommentsComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
    MatCardModule,
    MatListModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatButtonModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireAnalyticsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatIconModule,
    MatExpansionModule,
    FormsModule,
    MatMenuModule,
    MatChipsModule,
    ScullyLibModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
