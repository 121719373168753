<form [formGroup]="portraitForm" (ngSubmit)="submit()">
  <mat-card class="mat-elevation-z8">
    <mat-card-content>
      <h2>General profile information</h2>

      <div class="row">
        <mat-form-field>
          <mat-label>Profile name</mat-label>
          <input matInput formControlName="familyName" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>(Optional) Background picture URL</mat-label>
          <input matInput formControlName="backgroundUrl" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Alternative picture</mat-label>
          <mat-select>
            <mat-option value="random_nature">
              Random nature
            </mat-option>
            <mat-option value="your_city">
              Your city
            </mat-option>
            <mat-option value="reddit">
              Reddit
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field>
          <mat-label>Location</mat-label>
          <input matInput formControlName="location" />
          <mat-hint>Format: City, Country, Continent</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label>(Optional) URL</mat-label>
          <input matInput formControlName="websiteUrl" />
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field>
          <mat-label>Number of adults</mat-label>
          <mat-select formControlName="adultCount">
            <mat-option
              *ngFor="let option of adultOptions"
              [value]="option.value"
            >
              {{ option.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Number of children</mat-label>
          <mat-select formControlName="childrenCount">
            <mat-option
              *ngFor="let option of childrenOptions"
              [value]="option.value"
            >
              {{ option.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field>
          <mat-label>Financial goal</mat-label>
          <mat-select formControlName="financialGoal">
            <mat-option
              *ngFor="let option of financialGoalOptions"
              [value]="option.value"
            >
              {{ option.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Frugality</mat-label>
          <mat-select formControlName="frugality">
            <mat-option
              *ngFor="let option of frugalityOptions"
              [value]="option.value"
            >
              {{ option.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-expansion-panel class="mat-elevation-z8">
    <mat-expansion-panel-header>
      <h2>(Optional) Profile details</h2>
    </mat-expansion-panel-header>

    <div formArrayName="adultDetails">
      <div *ngFor="let adult of adultDetails.controls; let i = index">
        <mat-card>
          <mat-card-content [formGroupName]="i">
            <div class="row">
              <mat-form-field>
                <mat-label>Name or pseudonym</mat-label>
                <input matInput formControlName="name" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>(Optional) Age</mat-label>
                <input matInput type="number" formControlName="age" />
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field>
                <mat-label>(Optional) Picture URL</mat-label>
                <input matInput formControlName="pictureUrl" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>(Optional) Profession</mat-label>
                <input matInput formControlName="profession" />
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field>
                <mat-label>(Optional) Brief bio</mat-label>
                <textarea matInput formControlName="briefBio"></textarea>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-card class="mat-elevation-z8">
    <mat-card-content formArrayName="financialInfo">
      <h2>Financial basics</h2>

      <div *ngFor="let oneInfo of financialInfo.controls; let i = index">
        <div [formGroupName]="i">
          <p>
            You can leave any of these empty if you don't know or don't want to
            share. A timestamp helps to show progression of networth and other
            financial values.
          </p>
          <div class="row">
            <mat-form-field>
              <mat-label>(Optional) Timestamp</mat-label>
              <input
                matInput
                [matDatepicker]="myDatepicker"
                formControlName="timestamp"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="myDatepicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #myDatepicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <mat-label>(Optional) Networth</mat-label>
              <input matInput type="number" formControlName="networth" />
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field>
              <mat-label>(Optional) Monthly income (before taxes)</mat-label>
              <input matInput type="number" formControlName="monthlyIncome" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>(Optional) Monthly expenses</mat-label>
              <input matInput type="number" formControlName="monthlyExpenses" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <button
          mat-raised-button
          color="secondary"
          type="button"
          (click)="addFinancialBasics()"
        >
          Add another
        </button>
        <button
          mat-raised-button
          color="secondary"
          type="button"
          [disabled]="financialInfo.controls.length === 1"
          (click)="removeFinancialBasics()"
        >
          Remove last
        </button>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="mat-elevation-z8">
    <mat-card-content formArrayName="budgetInfo">
      <h2>Budget or expense summary</h2>

      <div *ngFor="let oneInfo of budgetInfo.controls; let i = index">
        <div [formGroupName]="i">
          <div class="row">
            <mat-form-field>
              <mat-label>Type of budget</mat-label>
              <mat-select formControlName="budgetStyle">
                <mat-option
                  *ngFor="let budgetOption of budgetOptions"
                  [value]="budgetOption.value"
                >
                  {{ budgetOption.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="shouldShowDurationSelection(oneInfo)">
              <mat-label>Duration (days)</mat-label>
              <input matInput formControlName="budgetingDays" />
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field>
              <mat-label>Currency code</mat-label>
              <input matInput formControlName="currencyCode" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>(Optional) Location for the budget</mat-label>
              <input matInput formControlName="location" />
              <mat-hint>Format: City, Country, Continent</mat-hint>
            </mat-form-field>
            <mat-form-field>
              <mat-label>(Optional) Timestamp</mat-label>
              <input
                matInput
                [matDatepicker]="myDatepicker"
                formControlName="timestamp"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="myDatepicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #myDatepicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div formGroupName="budgetingLines">
            <div class="row">
              <mat-form-field>
                <mat-label>Housing and utilities</mat-label>
                <input matInput type="number" formControlName="housing" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Personal items</mat-label>
                <input matInput type="number" formControlName="personalItems" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Transportation</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="transportation"
                />
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field>
                <mat-label>General child expenses</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="generalChildrenExpense"
                />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Food (groceries, restaurants)</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="foodAndRestaurants"
                />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Medical</mat-label>
                <input matInput type="number" formControlName="medical" />
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field>
                <mat-label>Vacation</mat-label>
                <input matInput type="number" formControlName="vacation" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Taxes</mat-label>
                <input matInput type="number" formControlName="tax" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Other expenses</mat-label>
                <input matInput type="number" formControlName="other" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <mat-form-field>
              <mat-label>(Optional) More information</mat-label>
              <textarea matInput formControlName="moreInfo"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <button
          mat-raised-button
          color="secondary"
          type="button"
          (click)="addBudget()"
        >
          Add another
        </button>
        <button
          mat-raised-button
          color="secondary"
          type="button"
          [disabled]="budgetInfo.controls.length === 1"
          (click)="removeBudget()"
        >
          Remove last
        </button>
      </div>
    </mat-card-content>
  </mat-card>

  <button
    mat-raised-button
    color="primary"
    type="submit"
    [disabled]="!portraitForm.valid"
  >
    Submit
  </button>
</form>
