<mat-toolbar color="primary" class="mat-elevation-z6 app-toolbar">
  <img src="assets/flame_only.png"/>
  <span class="title">FIRE Hungry</span>

  <!-- This fills the remaining space of the current row -->
  <span style="flex: 1 1 auto;"></span>

  <a mat-button routerLink="/home" queryParamsHandling="preserve"> Home </a>
  <a mat-button routerLink="/financial-profiles" queryParamsHandling="preserve">
    Profiles
  </a>
  <a mat-button routerLink="/snippets" queryParamsHandling="preserve">
    Snippets
  </a>
</mat-toolbar>
<router-outlet></router-outlet>

<div class="bottom-section">
  <div class="centered-text">
    <a mat-button routerLink="/terms-of-service" queryParamsHandling="preserve">
      Terms of Use
    </a>
    <a mat-button routerLink="/privacy-policy" queryParamsHandling="preserve">
      Privacy Policy
    </a>
    <span> Copyright FIREHungry.com 2020 </span>
  </div>
</div>
