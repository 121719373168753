import { Snippet } from 'src/app/model/snippet';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FirebaseService } from 'src/app/firebase.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit-snippet',
  templateUrl: './edit-snippet.component.html',
  styleUrls: ['./edit-snippet.component.scss'],
})
export class EditSnippetComponent implements OnInit {
  snippetForm = this.fb.group({
    title: ['', Validators.required],
    text: ['', Validators.required],
    tags: [],
    learnMoreUrl: [],
    profileId: [],
  });

  editingExisting = false;
  existingSnippet: Snippet | undefined;

  isSpecialUser = false;
  prod = false;

  constructor(
    private fb: FormBuilder,
    private firebaseService: FirebaseService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.prod = this.route.snapshot.paramMap.get('public') === 'true';

    const snippetId = this.route.snapshot.paramMap.get('snippetId');
    if (snippetId && snippetId !== 'new') {
      this.loadSnippetForEdit(snippetId);
      this.editingExisting = true;
    }

    this.firebaseService.isSpecialUser().then((special) => {
      this.isSpecialUser = special;
    });
  }

  async submit() {
    const values = this.snippetForm.value;
    const snippet: Snippet = {
      title: values.title,
      text: values.text,
      tags: ((values.tags as string) || '')
        .trim()
        .split(',')
        .filter((tag) => tag !== ''),
      learnMoreUrl: values.learnMoreUrl,
      profileOriginId: values.profileId,

      // Make sure to include new update dates
      lastUpdatedDate: new Date(),
      submissionDate: this.editingExisting
        ? this.existingSnippet.submissionDate
        : new Date(),
    };

    const snippetId = this.route.snapshot.paramMap.get('snippetId');
    const hasSnippetId = snippetId && snippetId !== 'new';
    if (!this.prod && hasSnippetId) {
      snippet.snippetId = snippetId;
    } else if (hasSnippetId) {
      snippet.updateForSnippetId = snippetId;
    }

    const newSnippetId = await this.firebaseService.saveSnippet(snippet, false);
    this.snackBar.open('Successfully saved the profile. Thanks!', undefined, {
      duration: 2000,
    });

    this.router.navigateByUrl(`/snippets/${newSnippetId}/public/false`);
  }

  private async loadSnippetForEdit(snippetId: string) {
    const snippetsObj =
      (await this.firebaseService.listSnippets(this.prod)) || {};
    const snippet = snippetsObj[snippetId];
    if (!snippet) {
      // TODO: Error
      return;
    }

    this.snippetForm.patchValue({
      title: snippet.title,
      text: snippet.text,
      tags: (snippet.tags || []).join(','),
      learnMoreUrl: snippet.learnMoreUrl,
      profileId: snippet.profileOriginId,
    });

    this.existingSnippet = snippet;
  }
}
