import { MatSnackBar } from '@angular/material/snack-bar';
import { Comment } from './../model/portrait';
import { FirebaseService } from 'src/app/firebase.service';
import { Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit {
  @Input()
  parentId: string;

  writeCommentForm = this.fb.group({
    author: ['', Validators.required],
    text: ['', Validators.required],
    financialIndependence: [''],
  });

  comments: Comment[] | undefined;

  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.reloadComments();
  }

  sortComments(a: Comment, b: Comment) {
    return a.date < b.date ? 1 : -1;
  }

  async reloadComments() {
    this.comments = Object.values(
      (await this.firebaseService.listComments(this.parentId)) || {}
    ).sort(this.sortComments);
  }

  async submitComment() {
    const values = this.writeCommentForm.value;
    if (values.financialIndependence !== 'financial independence') {
      console.log('Oops! Fix the typo');
      this.snackBar.open(
        'Please check your spelling, it should be "financial independence"',
        undefined,
        {
          duration: 2000,
        }
      );
      return;
    }

    const comment: Comment = {
      author: values.author,
      date: new Date(),
      text: values.text,
      parentId: this.parentId,
    };
    await this.firebaseService.saveComment(comment);

    this.reloadComments();

    this.snackBar.open('Successfully saved the comment. Thanks!', undefined, {
      duration: 2000,
    });
  }
}
