import { AngularFireAuth } from '@angular/fire/auth';
import { Subscription, merge, Observable } from 'rxjs';
import { Frugality, FinancialGoal } from 'src/app/model/portrait';
import {
  FamilyProfileModel,
  PartialFamilyProfileViewModel,
} from '../../model/portrait';
import { FirebaseService } from '../../firebase.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-view-profiles',
  templateUrl: './view-profiles.component.html',
  styleUrls: ['./view-profiles.component.scss'],
})
export class ViewProfilesComponent implements OnInit, OnDestroy {
  profiles: PartialFamilyProfileViewModel[] | undefined;
  allProfiles: PartialFamilyProfileViewModel[] | undefined;

  browseForm = this.fb.group({
    includeUnconfirmed: [this.shouldIncludeUnconfirmed()],
    search: [],
  });

  isSpecialUser$: Observable<boolean> | undefined;

  includeUnconfirmedSubscription: Subscription | undefined;

  searchSubscription: Subscription | undefined;

  constructor(
    private firebaseService: FirebaseService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private auth: AngularFireAuth
  ) {}

  shouldIncludeUnconfirmed() {
    return (
      this.route.snapshot.queryParamMap.get('includeUnconfirmed') === 'true'
    );
  }

  async reloadProfiles() {
    const includeUnconfirmed = this.shouldIncludeUnconfirmed();
    let profilesObj = (await this.firebaseService.listProfiles(true)) || {};

    if (includeUnconfirmed) {
      const unconfirmedObj =
        (await this.firebaseService.listProfiles(false)) || {};
      profilesObj = {
        ...profilesObj,
        ...unconfirmedObj,
      };
    }
    this.allProfiles = Array.from(Object.values(profilesObj));

    this.updateVisibleProfiles(this.browseForm.value['search']);
  }

  updateVisibleProfiles(value: string | undefined) {
    // Relies on profiles to exist
    if (!value || value.length === 0) {
      this.profiles = this.allProfiles;
      return;
    }
    this.profiles = this.allProfiles.filter((profile) => {
      return JSON.stringify(profile)
        .toLowerCase()
        .includes(value.toLowerCase());
    });
  }

  async ngOnInit(): Promise<void> {
    this.includeUnconfirmedSubscription = this.browseForm
      .get('includeUnconfirmed')
      .valueChanges.subscribe(async (val) => {
        await this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { includeUnconfirmed: val },
          queryParamsHandling: 'merge',
        });
        this.reloadProfiles();
      });

    this.searchSubscription = this.browseForm
      .get('search')
      .valueChanges.pipe(debounceTime(250))
      .subscribe((val) => this.updateVisibleProfiles(val));

    // TODO: Show some loading symbol.
    this.reloadProfiles();

    this.isSpecialUser$ = this.firebaseService.isSpecialUserObservable();
  }

  ngOnDestroy() {
    if (this.includeUnconfirmedSubscription) {
      this.includeUnconfirmedSubscription.unsubscribe();
      this.includeUnconfirmedSubscription = undefined;
    }

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
      this.searchSubscription = undefined;
    }
  }

  financialGoalString(profile: FamilyProfileModel) {
    switch (profile.familyInfo.financialGoal) {
      case FinancialGoal.DEBT_FREE:
        return 'Debt free';
      case FinancialGoal.FINANCIAL_INDEPENDENCE:
        return 'Financial indepencence';
      default:
        return 'Unknown';
    }
  }

  frugalityString(profile: FamilyProfileModel) {
    switch (profile.familyInfo.frugality) {
      case Frugality.FRUGAL:
        return 'Frugal';
      case Frugality.LAVISH:
        return 'Lavish';
      case Frugality.TYPICAL:
        return 'Typical';
      default:
        return 'Unknown';
    }
  }

  changePublish(profile: PartialFamilyProfileViewModel) {
    if (profile.prod) {
      this.firebaseService.moveToStaging(profile.profileId);
    } else {
      this.firebaseService.moveToProd(profile.profileId);
    }
  }
}
