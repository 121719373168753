<div class="global-top-section">
  <div>
    <div class="global-big-heading">
      Terms of use
    </div>
    <div class="global-subtitle">
      Because we want to set clear expectations.
    </div>
  </div>
</div>

<div class="container">
  <p>
    1. Terms
  </p>
  <p>
    By accessing the website at https://www.FIREHungry.com, you are agreeing to
    be bound by these terms of service, all applicable laws and regulations, and
    agree that you are responsible for compliance with any applicable local
    laws. If you do not agree with any of these terms, you are prohibited from
    using or accessing this site. The materials contained in this website are
    protected by applicable copyright and trademark law.
  </p>
  <br />
  <p>
    2. Use License
  </p>
  <p>
    Permission is granted to temporarily download one copy of the materials
    (information or software) on FIRE Hungry's website for personal,
    non-commercial transitory viewing only. This is the grant of a license, not
    a transfer of title, and under this license you may not:
  </p>
  <br />
  <ul>
    <li>
      <p>
        modify or copy the materials;
      </p>
    </li>
    <li>
      <p>
        use the materials for any commercial purpose, or for any public display
        (commercial or non-commercial);
      </p>
    </li>
    <li>
      <p>
        attempt to decompile or reverse engineer any software contained on FIRE
        Hungry's website;
      </p>
    </li>
    <li>
      <p>
        remove any copyright or other proprietary notations from the materials;
        or
      </p>
    </li>
    <li>
      <p>
        transfer the materials to another person or "mirror" the materials on
        any other server.
      </p>
    </li>
    <li>
      <p>
        This license shall automatically terminate if you violate any of these
        restrictions and may be terminated by FIRE Hungry at any time. Upon
        terminating your viewing of these materials or upon the termination of
        this license, you must destroy any downloaded materials in your
        possession whether in electronic or printed format.
      </p>
    </li>
  </ul>
  <br />
  <p>
    3. Disclaimer
  </p>
  <p>
    The materials on FIRE Hungry's website are provided on an 'as is' basis.
    FIRE Hungry makes no warranties, expressed or implied, and hereby disclaims
    and negates all other warranties including, without limitation, implied
    warranties or conditions of merchantability, fitness for a particular
    purpose, or non-infringement of intellectual property or other violation of
    rights.
  </p>
  <br />
  <p>
    Further, FIRE Hungry does not warrant or make any representations concerning
    the accuracy, likely results, or reliability of the use of the materials on
    its website or otherwise relating to such materials or on any sites linked
    to this site.
  </p>
  <br />
  <p>
    4. Limitations
  </p>
  <p>
    In no event shall FIRE Hungry or its suppliers be liable for any damages
    (including, without limitation, damages for loss of data or profit, or due
    to business interruption) arising out of the use or inability to use the
    materials on FIRE Hungry's website, even if FIRE Hungry or a FIRE Hungry
    authorized representative has been notified orally or in writing of the
    possibility of such damage. Because some jurisdictions do not allow
    limitations on implied warranties, or limitations of liability for
    consequential or incidental damages, these limitations may not apply to you.
  </p>
  <br />
  <p>
    5. Accuracy of materials
  </p>
  <p>
    Data on FIRE Hungry is crowdsourced from lots of people's inputs. By the
    nature of the data, it's impossible to get it completely accurate.
    Therefore, we accept no responsibility for any loss, injury or inconvenience
    resulting from using FIRE Hungry's websites and apps. You should verify any
    information before you make decisions based on them.
  </p>
  <br />
  <p>
    The materials appearing on FIRE Hungry's website could include technical,
    typographical, or photographic errors. FIRE Hungry does not warrant that any
    of the materials on its website are accurate, complete or current. FIRE
    Hungry may make changes to the materials contained on its website at any
    time without notice. However FIRE Hungry does not make any commitment to
    update the materials.
  </p>
  <br />
  <p>
    6. Links
  </p>
  <p>
    FIRE Hungry has not reviewed all of the sites linked to its website and is
    not responsible for the contents of any such linked site. The inclusion of
    any link does not imply endorsement by FIRE Hungry of the site. Use of any
    such linked website is at the user's own risk.
  </p>
  <br />
  <p>
    7. Modifications
  </p>
  <p>
    FIRE Hungry may revise these terms of service for its website at any time
    without notice. By using this website you are agreeing to be bound by the
    then current version of these terms of service.
  </p>
  <br />
  <p>
    8. Governing Law
  </p>
  <p>
    These terms and conditions are governed by and construed in accordance with
    the laws of the State of Washington in the United States of America, and you
    irrevocably submit to the exclusive jurisdiction of the courts in that State
    or location.
  </p>
  <br />
  <p>
    8. Account Security
  </p>
  <p>
    To use certain features of the Website or its Content, you may need a
    username and password. You are responsible for maintaining the
    confidentiality of the username and password, and you are responsible for
    all activities, whether by you or by others, that occur under your username
    or password and within your account. You agree to notify us immediately of
    any unauthorized or improper use of your username or password or any other
    breach of security. To help protect against unauthorized or improper use,
    make sure that you log out at the end of each session requiring your
    username and password.
  </p>
  <br />
  <p>
    It is your responsibility to protect your own username and password from
    disclosure to others. We cannot and will not be liable for any loss or
    damage arising from your failure to protect your username, password or
    account information. If you share your username or password with others,
    they may be able to obtain access to your personal information at your own
    risk.
  </p>
  <br />
  <p>
    By using our Website and its Content you agree to enter true and accurate
    information on the Website and its Content. If you enter a bogus email
    address, we have the right to immediately inactivate your account.
  </p>
  <br />
  <p>
    We will use our best efforts to keep your username and password(s) private
    and will not otherwise share your password(s) without your consent, except
    as necessary when the law requires it or in the good faith belief that such
    action is necessary, particularly when disclosure is necessary to identify,
    contact or bring legal action against someone who may be causing injury to
    others or interfering with our rights or property.
  </p>
  <br />
  <p>
    9. Information Collection
  </p>
  <p>
    Like many site operators, we collect information that your browser sends
    whenever you visit our Site (Log Data).
  </p>
  <br />
  <p>
    This Log Data may include information such as your computer's Internet
    Protocol ("IP") address, browser type, browser version, the pages of our
    Site that you visit, the time and date of your visit, the time spent on
    those pages and other statistics.
  </p>
  <br />
  <p>
    In addition, we may use third party services such as Google Analytics that
    collect, monitor and analyze this information for the purpose of improving
    our site.
  </p>
  <br />
  <p>
    Some features of this site rely on the usage of IP-based geolocation
    services, which identify your approximate location based upon the public IP
    address in use. These features are designed to provide you with information
    relevant to your location.
  </p>
  <br />
  <p>
    10. Affiliate Links
  </p>
  <p>
    Various links on FIRE Hungry may lead to external partners. If you visit or
    patronize one of these partners, FIRE Hungry may receive compensation. FIRE
    Hungry does not monitor or ensure the accuracy or performance of externally
    linked sites or partners. Our affiliate partners do not monitor content on
    FIRE Hungry for accuracy, including information regarding their products or
    services. Though reasonable efforts are made to ensure accuracy, no warranty
    is expressed or implied regarding product details or offers.
  </p>
  <br />
  <p>
    11. Availability and Downtime
  </p>
  <p>
    FIRE Hungry does not guarantee constant availability of Website access and
    accepts no liability for down time or access failure due to any
    circumstances.
  </p>
  <br />
  <p>
    FIRE Hungry'S SOFTWARE, APPS, WEBSITE, COMMUNITIES AND ANY OF ITS OTHER
    PROPERTIES ARE PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
    IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
    FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
    AUTHORS, OWNERS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR
    OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE,
    ARISING FROM, OUT OF OR IN CONNECTION WITH FIRE Hungry'S SOFTWARE, APPS,
    WEBSITE, COMMUNITIES AND ANY OF ITS OTHER PROPERTIES OR THE USE OR OTHER
    DEALINGS IN FIRE Hungry'S SOFTWARE, APPS, WEBSITE, COMMUNITIES AND ANY OF
    ITS OTHER PROPERTIES.
  </p>
</div>
