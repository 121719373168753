<div class="container" *ngIf="currentSnippet">
  <mat-card class="mat-elevation-z8">
    <mat-card-title>
      <mat-icon *ngIf="!currentSnippet.prod">hourglass_empty</mat-icon>
      {{ currentSnippet.title }}
    </mat-card-title>
    <mat-card-content>
      <mat-chip-list>
        <mat-chip *ngFor="let tag of tags">{{ tag }}</mat-chip>
      </mat-chip-list>
      <div class="snippet-text">
        {{ currentSnippet.text }}
      </div>
      <div class="extra-info" *ngIf="currentSnippet.learnMoreUrl">
        Learn more <a [href]="currentSnippet.learnMoreUrl">here</a>
      </div>
      <div class="extra-info" *ngIf="lastUpdatedDate">
        Last updated on {{ lastUpdatedDate }}.
      </div>
    </mat-card-content>
  </mat-card>
  <app-comments [parentId]="currentSnippet.snippetId"> </app-comments>
</div>
