import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnInit } from '@angular/core';
import { auth } from 'firebase';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    public fireAuth: AngularFireAuth,
    private title: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.title.setTitle(
      "FIREHungry - Let's gain financial independence together"
    );
    this.meta.addTag({
      name: 'description',
      content:
        'FIREHungry provides inspiration to gain Financial Independence and Retire Early. ' +
        'View and share profiles of successful or up and coming FIRE-enthusiasts, ' +
        'and quickly learn the basics through snippets.',
    });
    this.meta.addTag({ name: 'robots', content: 'INDEX, FOLLOW' });
  }

  login() {
    this.fireAuth.signInWithPopup(new auth.GoogleAuthProvider());
  }
  logout() {
    this.fireAuth.signOut();
  }
}
