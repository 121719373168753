import { FirebaseService } from './../../firebase.service';
import {
  FamilyProfileModel,
  FamilyProfileDetailsModel,
  PersonBio,
  BudgetStyle,
  FullFamilyProfileViewModel,
} from './../../model/portrait';
import { FinancialGoal, Frugality } from 'src/app/model/portrait';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatSnackBarModule, MatSnackBar } from '@angular/material/snack-bar';

import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-portrait',
  templateUrl: './edit-portrait.component.html',
  styleUrls: ['./edit-portrait.component.scss'],
})
export class EditPortraitComponent implements OnInit {
  financialGoalOptions = [
    {
      viewValue: 'Unknown',
      value: FinancialGoal.UNKNOWN,
    },
    {
      viewValue: 'Debt free',
      value: FinancialGoal.DEBT_FREE,
    },
    {
      viewValue: 'Financial independence',
      value: FinancialGoal.FINANCIAL_INDEPENDENCE,
    },
  ];

  frugalityOptions = [
    {
      viewValue: 'Unknown',
      value: Frugality.UNKNOWN,
    },
    {
      viewValue: 'Frugal',
      value: Frugality.FRUGAL,
    },
    {
      viewValue: 'Typical',
      value: Frugality.TYPICAL,
    },
    {
      viewValue: 'Lavish',
      value: Frugality.LAVISH,
    },
  ];

  budgetOptions = [
    {
      viewValue: 'Unknown',
      value: BudgetStyle.UNKNOWN,
    },
    {
      viewValue: 'Living there',
      value: BudgetStyle.LIVING_THERE,
    },
    {
      viewValue: 'Tourist/Vacation',
      value: BudgetStyle.TOURIST,
    },
  ];

  adultOptions = [];

  childrenOptions = [];

  editingExisting = false;

  portraitForm = this.fb.group({
    familyName: ['', Validators.required],
    location: ['', Validators.required],
    backgroundUrl: [''],
    financialGoal: [0],
    frugality: [0],
    adultCount: [2],
    childrenCount: [0],
    childrenAges: this.fb.array([]),

    websiteUrl: [''],
    instagramUrl: [''],
    twitterUrl: [''],
    emailAddress: [''],

    adultDetails: this.fb.array([this.getAdultGroup(), this.getAdultGroup()]),

    financialInfo: this.fb.array([this.getFinancialGroup()]),
    budgetInfo: this.fb.array([this.getBudgetGroup()]),
  });

  adultCountSubscription: Subscription | undefined;

  isSpecialUser = false;
  prod = false;
  existingProfile: FullFamilyProfileViewModel | undefined;

  constructor(
    private fb: FormBuilder,
    private firebaseService: FirebaseService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.childrenOptions.push({
      viewValue: 'No children',
      value: 0,
    });
    for (let i = 1; i <= 10; i++) {
      this.adultOptions.push({
        viewValue: i + ' adults',
        value: i,
      });
      this.childrenOptions.push({
        viewValue: i + ' children',
        value: i,
      });
    }

    this.adultCountSubscription = this.portraitForm
      .get('adultCount')
      .valueChanges.subscribe((value) => this.handleAdultCount(value));

    this.prod = this.route.snapshot.paramMap.get('public') === 'true';

    const profileId = this.route.snapshot.paramMap.get('profileId');
    if (profileId && profileId !== 'new') {
      this.loadProfileForEdit(profileId);
      this.editingExisting = true;
    }

    this.firebaseService.isSpecialUser().then((special) => {
      this.isSpecialUser = special;
    });
  }

  private async loadProfileForEdit(profileId: string) {
    const viewModel = await this.firebaseService.getFullFamilyProfileViewModel(
      profileId,
      this.prod
    );

    this.existingProfile = viewModel;

    // Make sure there is the right number of financial groups and budget groups.
    for (let i = 1; i < viewModel.financialInfo.length; i++) {
      this.addFinancialBasics();
    }

    for (let i = 1; i < viewModel.budgetInfo.length; i++) {
      this.addBudget();
    }

    this.portraitForm.patchValue({
      familyName: viewModel.familyInfo.familyName,
      location: viewModel.familyInfo.location,
      backgroundUrl: viewModel.familyInfo.backgroundUrl,
      financialGoal: viewModel.familyInfo.financialGoal,
      frugality: viewModel.familyInfo.frugality,
      adultCount: viewModel.familyInfo.adultCount,
      childrenCount: viewModel.familyInfo.childrenCount,
      childrenAges: viewModel.familyInfo.childrenAges || [],

      adultDetails: viewModel.adultDetails,
      financialInfo: viewModel.financialInfo,
      budgetInfo: viewModel.budgetInfo,
    });

    if (viewModel.linkInfo) {
      this.portraitForm.patchValue({
        instagramUrl: viewModel.linkInfo.instagramUrl,
        twitterUrl: viewModel.linkInfo.twitterUrl,
        emailAddress: viewModel.linkInfo.emailAddress,
        websiteUrl: viewModel.linkInfo.websiteUrl,
      });
    }
  }

  handleAdultCount(count: number) {
    const currentCount = (this.portraitForm.get('adultDetails') as FormArray)
      .length;
    if (count > currentCount) {
      for (let i = 0; i < count - currentCount; i++) {
        (this.portraitForm.get('adultDetails') as FormArray).push(
          this.getAdultGroup()
        );
      }
    } else {
      for (let i = currentCount - 1; i > count - 1; i--) {
        (this.portraitForm.get('adultDetails') as FormArray).removeAt(i);
      }
    }
  }

  getAdultGroup() {
    return this.fb.group({
      name: [''],
      age: [''],
      pictureUrl: [''],
      profession: [''],
      briefBio: [''],
    });
  }

  getFinancialGroup() {
    return this.fb.group({
      timestamp: [],
      networth: [''],
      monthlyIncome: [''],
      monthlyExpenses: [''],
    });
  }

  getBudgetGroup() {
    return this.fb.group({
      currencyCode: ['USD', Validators.required],
      location: [''],
      timestamp: [],
      moreInfo: [''],
      budgetStyle: [0],
      budgetingDays: [30],
      budgetingLines: this.fb.group({
        vacation: [],
        personalItems: [],
        housing: [],
        generalChildrenExpense: [],
        childrenEducation: [],
        other: [],
        tax: [],
        medical: [],
        transportation: [],
        foodAndRestaurants: [],
      }),
    });
  }

  shouldShowDurationSelection(oneBudgetInfo: FormControl) {
    return oneBudgetInfo.get('budgetStyle').value === BudgetStyle.TOURIST;
  }

  get adultDetails() {
    return this.portraitForm.get('adultDetails') as FormArray;
  }
  get financialInfo() {
    return this.portraitForm.get('financialInfo') as FormArray;
  }
  get budgetInfo() {
    return this.portraitForm.get('budgetInfo') as FormArray;
  }

  async submit() {
    const formValues = this.portraitForm.value;
    const profileModel: FamilyProfileModel = {
      familyInfo: {
        childrenAges: formValues.childrenAges || [],
        familyName: formValues.familyName,
        backgroundUrl: formValues.backgroundUrl,
        adultCount: formValues.adultCount,
        childrenCount: formValues.childrenCount,
        location: formValues.location,
        financialGoal: formValues.financialGoal,
        frugality: formValues.frugality,
      },
      linkInfo: {
        websiteUrl: formValues.websiteUrl,
        instagramUrl: formValues.instagramUrl,
        twitterUrl: formValues.twitterUrl,
        emailAddress: formValues.emailAddress,
      },
    };

    const profileDetails: FamilyProfileDetailsModel = {
      adultDetails: this.getFilteredAdultDetails(),
      budgetInfo: this.getBudgetDetails(),
      financialInfo: this.getFinancialDetails(),
    };

    // Timestamp values are moments. Need to change to date.
    for (const financialInfo of profileDetails.financialInfo) {
      if (!financialInfo.timestamp) {
        delete financialInfo.timestamp;
      } else {
        financialInfo.timestamp = moment(financialInfo.timestamp).toDate();
      }
    }
    for (const budgetInfo of profileDetails.budgetInfo) {
      if (!budgetInfo.timestamp) {
        delete budgetInfo.timestamp;
      } else {
        budgetInfo.timestamp = moment(budgetInfo.timestamp).toDate();
      }
    }

    // TODO: Be able to deal with multiple networths.
    const currentFinancialInfo = profileDetails.financialInfo[0];
    profileModel.familyInfo = {
      ...profileModel.familyInfo,
      networth: currentFinancialInfo.networth,
      monthlyIncome: currentFinancialInfo.monthlyIncome,
      monthlyExpenses: currentFinancialInfo.monthlyExpenses,
    };

    const profileId = this.route.snapshot.paramMap.get('profileId');
    const hasProfileId = profileId && profileId !== 'new';
    if (hasProfileId) {
      // Carry over the things that may not be in the forms.
      profileModel.worldEditable = { ...this.existingProfile.worldEditable };
    }

    if (!this.prod && hasProfileId) {
      // If this is just editing something in staging, we can simply continue and update in place.
      profileModel.profileId = profileId;
      profileDetails.profileId = profileId;
    } else if (hasProfileId) {
      // However, if this is actually an update of something in prod, we will
      // create a *new* one and indicate that it is intended to update prod.
      profileModel.updateForProdProfileId = profileId;
    }

    // TODO: Warn if overwriting an existing one.
    const newProfileId = await this.firebaseService.saveFullFamilyProfile(
      profileModel,
      profileDetails,
      false /* Never save to prod */
    );
    this.snackBar.open('Successfully saved the profile. Thanks!', undefined, {
      duration: 2000,
    });

    // Navigate to the profile.
    this.router.navigateByUrl(`/profiles/${newProfileId}/public/false`, {
      queryParamsHandling: 'merge',
    });
  }

  getAdultDetails() {
    return this.portraitForm.get('adultDetails').value;
  }

  getFilteredAdultDetails() {
    const adultDetails: Partial<PersonBio>[] = this.portraitForm.get(
      'adultDetails'
    ).value;
    // Get only those where a name is specified.
    return adultDetails.filter(
      (person) => person.name && person.name.length > 0
    ) as PersonBio[];
  }

  getBudgetDetails() {
    return this.portraitForm.get('budgetInfo').value;
  }
  getFinancialDetails() {
    return this.portraitForm.get('financialInfo').value;
  }

  addFinancialBasics() {
    this.financialInfo.push(this.getFinancialGroup());
  }

  removeFinancialBasics() {
    this.financialInfo.removeAt(this.financialInfo.length - 1);
  }

  addBudget() {
    this.budgetInfo.push(this.getBudgetGroup());
  }

  removeBudget() {
    this.budgetInfo.removeAt(this.budgetInfo.length - 1);
  }
}
