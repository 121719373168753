import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { AboutComponent } from './components/about/about.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { EditSnippetComponent } from './components/edit-snippet/edit-snippet.component';
import { ViewSnippetsComponent } from './components/view-snippets/view-snippets.component';
import { ViewProfilesComponent } from './components/view-profiles/view-profiles.component';
import { EditPortraitComponent } from './components/edit-portrait/edit-portrait.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ProfileDetailsComponent } from './components/profile-details/profile-details.component';
import { TosComponent } from './components/tos/tos.component';
import { SnippetDetailsComponent } from './snippet-details/snippet-details.component';

// TODO: SEO-friendly route names
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    canActivate: [],
    component: HomeComponent,
  },
  {
    path: 'profiles/:profileId/public/:public',
    canActivate: [],
    component: ProfileDetailsComponent,
  },
  // For why, see: https://stackoverflow.com/questions/34208745/angular-2-optional-route-parameter
  {
    path: 'edit-profile',
    canActivate: [],
    redirectTo: 'edit-profile/new/public/false',
    pathMatch: 'full',
  },
  {
    path: 'edit-profile/:profileId/public/:public',
    canActivate: [],
    component: EditPortraitComponent,
  },
  {
    path: 'financial-profiles',
    canActivate: [],
    component: ViewProfilesComponent,
  },
  {
    path: 'snippets',
    canActivate: [],
    component: ViewSnippetsComponent,
  },
  {
    path: 'edit-snippet',
    canActivate: [],
    redirectTo: 'edit-snippet/',
    pathMatch: 'full',
  },
  {
    path: 'edit-snippet/:snippetId',
    canActivate: [],
    component: EditSnippetComponent,
  },
  {
    path: 'snippets/:snippetId/public/:public',
    canActivate: [],
    component: SnippetDetailsComponent,
  },
  {
    path: 'sign-in',
    canActivate: [],
    component: SignInComponent,
  },
  {
    path: 'about',
    canActivate: [],
    component: AboutComponent,
  },
  {
    path: 'terms-of-service',
    canActivate: [],
    component: TosComponent,
  },
  {
    path: 'privacy-policy',
    canActivate: [],
    component: PrivacyPolicyComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
