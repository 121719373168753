<form [formGroup]="snippetForm" (ngSubmit)="submit()">
  <mat-card>
    Common tags:
  </mat-card>
  <mat-card class="mat-elevation-z8">
    <mat-card-content>
      <h2>New snippet</h2>
      <div class="row">
        <mat-form-field>
          <mat-label>Title</mat-label>
          <input matInput formControlName="title" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>(Optional) Learn more URL</mat-label>
          <input matInput formControlName="learnMoreUrl" />
          <mat-hint align="start"
            >A URL to read more about this subject.</mat-hint
          >
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>Text</mat-label>
          <textarea matInput formControlName="text"></textarea>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>(Optional) Tags</mat-label>
          <input matInput formControlName="tags" />
          <mat-hint align="start">Separate multiple tags with comma.</mat-hint>
        </mat-form-field>

        <mat-form-field>
          <mat-label>(Optional) Profile Id</mat-label>
          <input matInput formControlName="profileId" />
          <mat-hint align="start"
            >If this is associated with one of the profiles.</mat-hint
          >
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>

  <button
    mat-raised-button
    color="primary"
    type="submit"
    [disabled]="!snippetForm.valid"
  >
    Submit
  </button>
</form>
