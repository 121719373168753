import { FirebaseService } from '../../firebase.service';
import {
  getCategoryDisplayString,
  FullFamilyProfileViewModel,
  Comment,
} from '../../model/portrait';
import { Component, OnInit } from '@angular/core';
import { FinancialGoal, Frugality } from 'src/app/model/portrait';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

interface BudgetLine {
  category: string;
  value?: number;
}

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
})
export class ProfileDetailsComponent implements OnInit {
  displayedColumns: string[] = ['category', 'value'];
  budgets: Array<BudgetLine[]> = [];

  currentPortrait: FullFamilyProfileViewModel | undefined;

  prod = true;

  isSpecialUser = false;

  // Hack: So that we do not need to reload, and simultaneously to check whether like was already done.
  alreadyLiked = 0;

  constructor(
    private route: ActivatedRoute,
    private firebaseService: FirebaseService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.prod = this.route.snapshot.paramMap.get('public') === 'true';
    const profileId = this.route.snapshot.paramMap.get('profileId');
    if (profileId) {
      // Load the profile.
      this.currentPortrait = await this.firebaseService.getFullFamilyProfileViewModel(
        profileId,
        this.prod
      );
    }
    this.populateBudgets();

    this.firebaseService.isSpecialUser().then((specialUser) => {
      this.isSpecialUser = specialUser;
    });
  }

  hasAnyAdultDetails() {
    return (
      this.currentPortrait.adultDetails &&
      this.currentPortrait.adultDetails.length > 0
    );
  }

  hasAnyLinkInfo() {
    if (!this.currentPortrait.linkInfo) {
      return false;
    }
    for (const val in Object.values(this.currentPortrait.linkInfo)) {
      if (val) {
        return true;
      }
    }

    return false;
  }

  populateBudgets() {
    // TODO: This takes only the first one currently
    this.budgets = [];
    for (const budgetInfo of this.currentPortrait.budgetInfo) {
      if (budgetInfo.budgetingLines) {
        this.budgets.push(
          Object.entries(budgetInfo.budgetingLines)
            .map((budgetingLine) => {
              return {
                category: getCategoryDisplayString(budgetingLine[0]),
                value: budgetingLine[1],
              };
            })
            .filter((budgetingLine) => !!budgetingLine.category)
        );
      }
    }
  }

  financialGoalString() {
    switch (this.currentPortrait.familyInfo.financialGoal) {
      case FinancialGoal.DEBT_FREE:
        return 'Debt free';
      case FinancialGoal.FINANCIAL_INDEPENDENCE:
        return 'Financial indepencence';
      default:
        return 'Unknown';
    }
  }

  frugalityString() {
    switch (this.currentPortrait.familyInfo.frugality) {
      case Frugality.FRUGAL:
        return 'Frugal';
      case Frugality.LAVISH:
        return 'Lavish';
      case Frugality.TYPICAL:
        return 'Typical';
      default:
        return 'Unknown';
    }
  }

  getTotalValue(budgetLines: BudgetLine[]) {
    let sum = 0;
    for (const line of budgetLines) {
      if (line.value) {
        sum += line.value;
      }
    }
    return sum;
  }

  async delete() {
    await this.firebaseService.deleteProfile(
      this.currentPortrait.profileId,
      this.prod
    );
    this.router.navigate(['/financial-profiles'], {
      queryParamsHandling: 'preserve',
    });
  }

  async like() {
    if (this.alreadyLiked !== 0) {
      return;
    }
    await this.firebaseService.incrementLike(
      this.currentPortrait.profileId,
      this.prod
    );
    this.alreadyLiked = 1;
  }
}
