<div class="global-top-section">
  <div>
    <div class="global-big-heading">
      Sorry! Page not found.
    </div>
    <div class="global-subtitle">
      Please check the URL or contact us at contact@firehungry.com
    </div>
  </div>
</div>
