<mat-card class="mat-elevation-z8">
  <mat-card-header>
    <mat-card-title>
      Comments and discussion
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-card *ngFor="let comment of comments">
      <mat-card-content>
        <div class="comment-title">Comment by {{ comment.author }}</div>
        <div class="comment-text">
          {{ comment.text }}
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <div>
          Any questions or comments about this profile? Leave them here!
        </div>
        <form [formGroup]="writeCommentForm" (ngSubmit)="submitComment()">
          <div class="row">
            <mat-form-field>
              <mat-label>Author</mat-label>
              <input matInput formControlName="author" />
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field>
              <mat-label>Comment text</mat-label>
              <textarea matInput formControlName="text"></textarea>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field>
              <mat-label
                >Please fix the typo in 'financial indepundence'</mat-label
              >
              <input matInput formControlName="financialIndependence" />
            </mat-form-field>
          </div>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!writeCommentForm.valid"
          >
            Submit
          </button>
        </form>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>
