<div class="global-top-section">
  <div>
    <div class="global-big-heading">
      Profiles
    </div>
    <div class="wrap">
      <div class="first">
        <!-- Search and filter mechanism -->
        These are crowd sourced profiles that show real-life budgets. Help the
        community and submit a profile - it can be your own, or based on a
        public blog or similar!
        <div>
          <a
            mat-raised-button
            color="primary"
            routerLink="/edit-profile"
            queryParamsHandling="preserve"
          >
            Submit new profile
          </a>
        </div>
      </div>
      <form [formGroup]="browseForm">
        <div>
          <mat-slide-toggle formControlName="includeUnconfirmed">
            Include unconfirmed submissions
          </mat-slide-toggle>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Search</mat-label>
            <input matInput formControlName="search" />
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="container">
  <mat-card
    *ngFor="let profile of profiles"
    [routerLink]="'/profiles/' + profile.profileId + '/public/' + profile.prod"
    queryParamsHandling="preserve"
    class="profile-card"
  >
    <mat-menu #appMenu="matMenu">
      This is the admin menu.
      <button mat-menu-item (click)="changePublish(profile)">
        Toggle publish
      </button>
    </mat-menu>

    <mat-card-title [class]="profile.prod ? 'prod-profile' : 'staging-profile'">
      <div>{{ profile.familyInfo.familyName }}</div>
      <button
        mat-icon-button
        [matMenuTriggerFor]="appMenu"
        (click)="$event.stopPropagation()"
        *ngIf="isSpecialUser$ | async"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </mat-card-title>
    <img
      class="card-filler-image"
      mat-card-image
      [src]="
        profile.familyInfo.backgroundUrl ||
        'https://upload.wikimedia.org/wikipedia/commons/9/91/Wonder_Lake_and_Denali.jpg'
      "
    />
    <mat-card-content>
      <div class="row">
        <span>Adults:</span>
        <span>{{ profile.familyInfo.adultCount }}</span>
      </div>
      <div class="row">
        <span>Children:</span>
        <span>{{ profile.familyInfo.childrenCount || 0 }}</span>
      </div>
      <div class="row">
        <span>Location:</span>
        <span>{{ profile.familyInfo.location }}</span>
      </div>
      <div class="row">
        <span>Financial goal:</span>
        <span>{{ financialGoalString(profile) }}</span>
      </div>
      <div class="row">
        <span>Frugality:</span>
        <span>{{ frugalityString(profile) }}</span>
      </div>
    </mat-card-content>
  </mat-card>
</div>
