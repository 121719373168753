import { FirebaseService } from 'src/app/firebase.service';
import { SnippetViewModel } from './../model/snippet';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-snippet-details',
  templateUrl: './snippet-details.component.html',
  styleUrls: ['./snippet-details.component.scss'],
})
export class SnippetDetailsComponent implements OnInit {
  currentSnippet: SnippetViewModel | undefined;
  prod = true;
  isSpecialUser = false;

  tags: string[] = [];

  lastUpdatedDate: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private firebaseService: FirebaseService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  async ngOnInit() {
    this.prod = this.route.snapshot.paramMap.get('public') === 'true';
    const snippetId = this.route.snapshot.paramMap.get('snippetId');
    if (snippetId) {
      const snippetsObj = await this.firebaseService.listSnippets(this.prod);
      this.currentSnippet = snippetsObj[snippetId];

      if (!this.currentSnippet) {
        // TODO: Error
      }
      this.tags = this.currentSnippet.tags || [];
      if (this.currentSnippet.lastUpdatedDate) {
        this.lastUpdatedDate = moment(
          this.currentSnippet.lastUpdatedDate
        ).format('MMM DD, YYYY');
      }
    }

    this.firebaseService.isSpecialUser().then((specialUser) => {
      this.isSpecialUser = specialUser;
    });
  }
}
