import { Subscription, Observable } from 'rxjs';
import { SnippetViewModel } from './../../model/snippet';
import { FirebaseService } from './../../firebase.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Snippet } from 'src/app/model/snippet';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-view-snippets',
  templateUrl: './view-snippets.component.html',
  styleUrls: ['./view-snippets.component.scss'],
})
export class ViewSnippetsComponent implements OnInit, OnDestroy {
  allSnippets: SnippetViewModel[] | undefined;
  snippets: SnippetViewModel[] | undefined;

  isSpecialUser$: Observable<boolean> | undefined;

  browseForm = this.fb.group({
    includeUnconfirmed: [this.shouldIncludeUnconfirmed()],
    search: [],
  });

  includeUnconfirmedSubscription: Subscription | undefined;
  searchSubscription: Subscription | undefined;

  constructor(
    private firebaseService: FirebaseService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  updateVisibleSnippets(value: string | undefined) {
    // Relies on profiles to exist
    if (!value || value.length === 0) {
      this.snippets = this.allSnippets;
      return;
    }
    this.snippets = this.allSnippets.filter((snippet) => {
      return JSON.stringify(snippet)
        .toLowerCase()
        .includes(value.toLowerCase());
    });
  }

  shouldIncludeUnconfirmed() {
    return (
      this.route.snapshot.queryParamMap.get('includeUnconfirmed') === 'true'
    );
  }

  getTags(snippet: SnippetViewModel) {
    if (!snippet) {
      return;
    }
    return snippet.tags || [];
  }

  async ngOnInit(): Promise<void> {
    this.includeUnconfirmedSubscription = this.browseForm
      .get('includeUnconfirmed')
      .valueChanges.subscribe(async (val) => {
        await this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { includeUnconfirmed: val },
          queryParamsHandling: 'merge',
        });
        this.reloadSnippets();
      });
    this.reloadSnippets();

    this.searchSubscription = this.browseForm
      .get('search')
      .valueChanges.pipe(debounceTime(250))
      .subscribe((val) => this.updateVisibleSnippets(val));

    this.isSpecialUser$ = this.firebaseService.isSpecialUserObservable();
  }

  ngOnDestroy() {
    if (this.includeUnconfirmedSubscription) {
      this.includeUnconfirmedSubscription.unsubscribe();
      this.includeUnconfirmedSubscription = undefined;
    }

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
      this.searchSubscription = undefined;
    }
  }

  async reloadSnippets() {
    const includeUnconfirmed = this.shouldIncludeUnconfirmed();

    let snippetsObj = (await this.firebaseService.listSnippets(true)) || {};
    if (includeUnconfirmed) {
      const unconfirmedSnippets =
        (await this.firebaseService.listSnippets(false)) || {};
      snippetsObj = {
        ...snippetsObj,
        ...unconfirmedSnippets,
      };
    }

    this.allSnippets = Array.from(Object.values(snippetsObj));

    this.updateVisibleSnippets(this.browseForm.value['search']);
  }

  async delete(snippet: SnippetViewModel) {
    await this.firebaseService.deleteSnippet(snippet.snippetId, snippet.prod);
  }

  changePublish(snippet: SnippetViewModel) {
    if (snippet.prod) {
      this.firebaseService.moveSnippetToStaging(snippet.snippetId);
    } else {
      this.firebaseService.moveSnippetToProd(snippet.snippetId);
    }
  }
}
