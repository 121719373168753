<div *ngIf="currentPortrait">
  <mat-card class="mat-elevation-z8">
    <mat-card-header>
      <mat-card-title>
        {{
          currentPortrait.familyInfo.familyName
            ? "Family snapshot: " + currentPortrait.familyInfo.familyName
            : "Family snapshot"
        }}
        <button
          mat-icon-button
          color="primary"
          aria-label="Edit button"
          *ngIf="isSpecialUser"
          [routerLink]="
            '/edit-profile/' +
            currentPortrait?.profileId +
            '/public/' +
            currentPortrait.prod
          "
          queryParamsHandling="preserve"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          aria-label="Delete button"
          *ngIf="isSpecialUser"
          (click)="delete()"
        >
          <mat-icon>delete</mat-icon>
        </button>

        <button
          mat-button
          color="primary"
          aria-label="Heart button"
          (click)="like()"
          [disabled]="alreadyLiked !== 0"
        >
          {{ (currentPortrait?.worldEditable?.likes || 0) + alreadyLiked }}

          <mat-icon>{{
            alreadyLiked ? "favorite" : "favorite_border"
          }}</mat-icon>
        </button>
      </mat-card-title>
    </mat-card-header>
    <img
      class="card-filler-image"
      mat-card-image
      [src]="
        currentPortrait.familyInfo.backgroundUrl ||
        'https://upload.wikimedia.org/wikipedia/commons/9/91/Wonder_Lake_and_Denali.jpg'
      "
    />

    <mat-card-content>
      <div class="section">
        <div class="row">
          <span>Adults:</span>
          <span>{{ currentPortrait.familyInfo.adultCount }}</span>
        </div>
        <div class="row">
          <span>Children:</span>
          <span>{{ currentPortrait.familyInfo.childrenCount || 0 }}</span>
        </div>
        <div class="row">
          <span>Location:</span>
          <span>{{ currentPortrait.familyInfo.location }}</span>
        </div>
        <div class="row">
          <span>Financial goal:</span>
          <span>{{ financialGoalString() }}</span>
        </div>
        <div class="row">
          <span>Frugality:</span>
          <span>{{ frugalityString() }}</span>
        </div>
      </div>

      <mat-divider [inset]="true" *ngIf="hasAnyAdultDetails()"></mat-divider>

      <div class="section" *ngIf="hasAnyAdultDetails()">
        <ng-container *ngFor="let person of currentPortrait.adultDetails">
          <div class="person">
            <img
              mat-card-avatar
              [src]="person.pictureUrl || 'assets/anonymous_user.png'"
            />
            <h2>{{ person.name }}</h2>
          </div>

          <div class="row">
            <span>Age:</span>
            <span>{{ person.age || "Unknown" }}</span>
          </div>
          <div class="row">
            <span>Profession:</span>
            <span>{{ person.profession || "Unknown" }}</span>
          </div>
          <div *ngIf="person.briefBio" class="row">
            <span>Brief bio:</span>
            <span class="brief-bio">{{ person.briefBio }}</span>
          </div>
        </ng-container>
      </div>

      <mat-divider [inset]="true" *ngIf="hasAnyLinkInfo()"></mat-divider>
      <div class="section" *ngIf="hasAnyLinkInfo()">
        <span>Want to learn more? </span>
        <div class="icons">
          <a
            [href]="currentPortrait.linkInfo.websiteUrl"
            *ngIf="currentPortrait.linkInfo.websiteUrl"
          >
            <img
              border="0"
              alt="Website"
              src="assets/link_icon.png"
              width="25"
              height="25"
            />
          </a>
          <a
            [href]="currentPortrait.linkInfo.instagramUrl"
            *ngIf="currentPortrait.linkInfo.instagramUrl"
          >
            <img
              border="0"
              alt="Instagram"
              src="assets/instagram_icon.png"
              width="25"
              height="25"
            />
          </a>
          <a
            [href]="currentPortrait.linkInfo.twitterUrl"
            *ngIf="currentPortrait.linkInfo.twitterUrl"
          >
            <img
              border="0"
              alt="Twitter"
              src="assets/twitter_icon.png"
              width="25"
              height="25"
            />
          </a>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card
    *ngFor="let financialInfo of currentPortrait.financialInfo"
    class="mat-elevation-z8"
  >
    <mat-card-header>
      <mat-card-title>
        Financial summary
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row" *ngIf="financialInfo.networth !== undefined">
        <span>Networth:</span>
        <span>{{ financialInfo.networth | currency }}</span>
      </div>
      <div class="row" *ngIf="financialInfo.monthlyIncome !== undefined">
        <span>Income (monthly):</span>
        <span>{{ financialInfo.monthlyIncome | currency }}</span>
      </div>
      <div class="row" *ngIf="financialInfo.monthlyExpenses !== undefined">
        <span>Expenses (monthly):</span>
        <span>{{ financialInfo.monthlyExpenses | currency }}</span>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngFor="let budgetLines of budgets" class="mat-elevation-z8">
    <mat-card-header>
      <mat-card-title>
        Budget
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>This family lives in {{ currentPortrait.familyInfo.location }}</p>
      <table mat-table [dataSource]="budgetLines">
        <!-- Category Column -->
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef>Category</th>
          <td mat-cell *matCellDef="let budget">{{ budget.category }}</td>
          <td mat-footer-cell *matFooterCellDef>Total</td>
        </ng-container>

        <!-- Value Column -->
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Value</th>
          <td mat-cell *matCellDef="let budget">
            <ng-container *ngIf="budget.value !== undefined; else na">
              {{ budget.value | currency }}</ng-container
            >
            <ng-template #na>N/A</ng-template>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ getTotalValue(budgetLines) | currency }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
    </mat-card-content>
  </mat-card>
  <app-comments [parentId]="currentPortrait.profileId"> </app-comments>
</div>
